import {
  BaseTcStorePayload,
  ColDefExtended,
  ColGroupDefExtended,
  ColumnNumberPerDevice,
  TcFilterDef,
  TcGridAddButtonConfig,
} from '@tc/abstract';
import { GridOptions } from 'ag-grid-community';
import { TcGridSort } from '../../interfaces/tc-grid.interface';

/**
 * init tc grid payload
 */
export interface InitTcGridPayload extends BaseTcStorePayload {
  columns: (ColDefExtended | ColGroupDefExtended)[];
  columnNumberPerDevice?: ColumnNumberPerDevice;
  gridOptions?: GridOptions;
  infiniteScrollPercent?: number;
  addButton?: TcGridAddButtonConfig;
  cssClass?: string;
}

/**
 * set tc grid columns payload
 */
export interface SetTcGridColumnsPayload extends BaseTcStorePayload {
  columns: (ColDefExtended | ColGroupDefExtended)[];
}

/**
 * load tc grid data payload
 */
export interface LoadTcGridDataPayload extends BaseTcStorePayload {
  skip?: number;
  sort?: TcGridSort;
  filter?: TcFilterDef;
}

/**
 * load tc grid data payload success
 */
export interface LoadTcGridDataPayloadSuccess<T extends object = object>
  extends BaseTcStorePayload {
  data: T[];
  total: number;
  take: number;
  skip: number;
}

/**
 * load tc grid more data payload
 */
export interface LoadTcGridMoreDataPayload extends LoadTcGridDataPayload {}

/**
 * load tc grid more data success payload
 */
export interface LoadTcGridMoreDataPayloadSuccess
  extends LoadTcGridDataPayloadSuccess {}

/**
 * update tc grid row payload
 */
export interface UpdateTcGridRowPayload<T extends object = object>
  extends BaseTcStorePayload {
  nodeId: any;
  colId: any;
  oldValue: any;
  newValue: any;
  rowData: T;
}

/**
 * update tc grid row payload success
 */
export interface UpdateTcGridRowPayloadSuccess extends BaseTcStorePayload {
  rowData: any;
}

/**
 * insert tc grid empty row payload
 */
export interface InsertTcGridEmptyRowPayload extends BaseTcStorePayload {}

/**
 * open tc grid details popup payload
 */
export interface OpenTcGridDetailsPopupPayload extends BaseTcStorePayload {
  detailsPopupComponent: string;
  data: any;
  width?: string;
  height?: string;
  supressCloseConfirmation?: boolean;
}

/**
 * open tc grid confirm popup where callbackAction is dispatched
 * after popup close on user confirmation
 */
export interface openTcGridConfirmPopupPayload
  extends OpenTcGridDetailsPopupPayload {
  callbackAction: any;
  actionPayload: any;
}

/**
 *  refresh tc grid data
 */
export interface RefreshTcGridDataPayload extends BaseTcStorePayload {
  filter: TcFilterDef;
}

/**
 *  filter tc grid success
 */
export interface RefreshTcGridDataSuccessPayload<T extends object = object>
  extends BaseTcStorePayload {
  filter: TcFilterDef;
}

export interface SetTcGridDetailsDialogIdPayload extends BaseTcStorePayload {
  dialogId: string;
}
