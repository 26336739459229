import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { MaterialColor, TcConfigTypes, TcDataProviderType } from '@tc/abstract';
import {
  addTcGridButtonClicked,
  deleteTcGridButtonClicked,
  editTcGridButtonClicked,
  TcSmartGridComponent,
} from '@tc/advanced-components';
import { getDeleteButtonConfig, MaterialButtonType } from '@tc/buttons';
import { TcGridCellComponent } from '@tc/core';
import { ACCESS_RIGHTS_STORE_KEY } from '../store/constants';

@Component({
  selector: 'app-access-rights-grid',
  templateUrl: './access-rights-grid.component.html',
  styleUrls: ['./access-rights-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccessRightsGridComponent
  extends TcSmartGridComponent
  implements OnInit
{
  readonly storeKey = ACCESS_RIGHTS_STORE_KEY;
  constructor(store$: Store<any>) {
    super(store$);
  }

  ngOnInit(): void {
    this._initListConfig();

    super.ngOnInit();
  }

  private _initListConfig() {
    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      cssClass: 'access-rights-grid',
      storeKey: this.storeKey,
      gridOptions: {},
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'accessRights',
        filter: {
          filters: [],
        },
        fields: 'userLogin, role',
      },
      addButton: {
        detailsPopupComponent: 'AccessRightsDetailComponent',
        action: addTcGridButtonClicked,
        showSecondaryAddButton: true,
        actionPayload: {
          width: '35em',
          height: '150',
          supressCloseConfirmation: true,
        },
      },
      columns: [
        {
          field: 'userLogin',
        },
        {
          field: 'role',
        },
        {
          field: '',
          minWidth: 85,
          maxWidth: 85,
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Primary,
                matIcon: 'edit',
                type: MaterialButtonType.Icon,
                action: editTcGridButtonClicked,
                actionPayload: {
                  width: '35em',
                  height: '150',
                  supressCloseConfirmation: true,
                  detailsPopupComponent: 'AccessRightsDetailComponent',
                },
              },
              getDeleteButtonConfig(deleteTcGridButtonClicked, {
                name: 'delete',
              }),
            ],
          },
        },
      ],
    };
  }
}
