import { createAction, props } from '@ngrx/store';
import { AccessRight } from '../interfaces/access-right.interface';
import { CheckConfigurationAlreadyExistsActionPayload } from './acccess-rights-payloads';

export const checkIfConfigurationExistsAndShowConfirmationDialog = createAction(
  `[Access Rights] Check If Configuration Exists And Show Confirmation Dialog`,
  props<CheckConfigurationAlreadyExistsActionPayload>()
);

export const showConfirmationDialogToOverrideExistingConfiguration =
  createAction(
    `[Access Rights] Show Confirmation Dialog To Override Existing Configuration`,
    props<{ storeKey: string; item: AccessRight }>()
  );
