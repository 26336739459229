import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { TcCoreModule } from '@tc/core';
import { LayoutModule } from '../modules/layout/layout.module';
import { CustomRoutingModule } from './custom-routing.module';
import { AccessRightsModule } from './modules/access-rights/access-rights.module';
import { AuthModule } from './modules/auth/auth.module';
import { InteractionsModule } from './modules/interactions/interactions.module';
import { InterlocuteursModule } from './modules/interlocuteurs/interlocuteurs.module';
import { MenuModule } from './modules/menu/menu.module';
import { OrganismesModule } from './modules/organismes/organismes.module';
import { AccessRightsPageComponent } from './pages/access-rights/access-rights-page.component';
import { ForbiddenPageComponent } from './pages/forbidden-page/forbidden-page.component';
import { HomeComponent } from './pages/home-page/home.component';
import { InteractionsFeedPageComponent } from './pages/interactions-feed-page/interactions-feed-page.component';
import { InteractionsPageComponent } from './pages/interactions-page/interactions-page.component';
import { InterlocuteursPageComponent } from './pages/interlocuteurs-page/interlocuteurs-page.component';
import { OrganismesPageComponent } from './pages/organismes-page/organismes-page.component';
import { TableauDeBordComponent } from './pages/tableau-de-bord/tableau-de-bord.component';
import { FonctionTypesOptionsResolver } from './resolvers/fonction-types-options.resolver';
import { FonctionTypesService } from './services/fonction-types.service';
import { customReducer } from './store/custom.reducers';
import { CUSTOM_STATE_KEY } from './store/custom.state';

const modules = [
  AuthModule,
  MenuModule,
  OrganismesModule,
  InterlocuteursModule,
  InteractionsModule,
  AccessRightsModule,
];

const pages = [
  HomeComponent,
  TableauDeBordComponent,
  OrganismesPageComponent,
  InterlocuteursPageComponent,
  InteractionsFeedPageComponent,
  InteractionsPageComponent,
  ForbiddenPageComponent,
  AccessRightsPageComponent,
];

const components = [...pages];

const services = [FonctionTypesService];

const resolvers = [FonctionTypesOptionsResolver];

@NgModule({
  declarations: [...components, ...pages],
  imports: [
    ...modules,
    CommonModule,
    TcCoreModule,
    CustomRoutingModule,
    LayoutModule,
    StoreModule.forFeature(CUSTOM_STATE_KEY, customReducer),
  ],
  exports: [...components],
  providers: [...services, ...resolvers],
})
export class CustomModule {
  constructor() {}
}
