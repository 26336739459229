import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { closeTcGridDetailsDialog } from '@tc/core';
import {
  createItemSuccess,
  DEFAULT_TC_DATA_STATE_KEY,
  deleteItemSuccess,
  getTcData,
  NgRxTcDataState,
  updateItem,
  updateItemSuccess,
} from '@tc/data-store';
import { TcPromptDialogComponent } from '@tc/dialog';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  getTcSmartFormCurrentModel,
  NgRxTcSmartFormState,
  submitTcSmartFormCurrentModel,
} from '@tc/smart-form';
import { hasValue } from '@tc/utils';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, first, take, tap } from 'rxjs/operators';
import { getAuthenticatedUser } from '../../../../modules/auth/store/auth.selectors';
import {
  checkIfConfigurationExistsAndShowConfirmationDialog,
  showConfirmationDialogToOverrideExistingConfiguration,
} from './access-rights.actions';
import { ACCESS_RIGHTS_STORE_KEY } from './constants';

@Injectable()
export class AccessRightsEffects {
  dataStore$: Observable<NgRxTcDataState>;
  formStore$: Observable<NgRxTcSmartFormState>;

  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions,
    private readonly dialog: MatDialog
  ) {
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.formStore$ = this.store$.pipe(
      select(DEFAULT_TC_SMART_FORM_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  /// This effect is used to reload the page when the user updates his own access rights
  forceReloadUserOnUpdateSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(updateItemSuccess, createItemSuccess, deleteItemSuccess),
        filter(({ storeKey }) => storeKey === ACCESS_RIGHTS_STORE_KEY),
        concatLatestFrom(() => this.store$.select(getAuthenticatedUser)),
        filter(([{ item }, user]) => item.userLogin === user.login),
        tap(() => {
          window.location.reload();
        })
      );
    },
    { dispatch: false }
  );

  showConfirmationDialogToOverrideExistingConfiguration$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(showConfirmationDialogToOverrideExistingConfiguration),
        filter(({ storeKey }) => storeKey === ACCESS_RIGHTS_STORE_KEY),
        tap(async ({ storeKey, item }) => {
          this.store$.dispatch(updateItem({ storeKey, item }));
          this.store$.dispatch(
            closeTcGridDetailsDialog({
              storeKey: storeKey,
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  checkIfConfigurationAlreadyExistsAndShowConfirmationDialog$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(checkIfConfigurationExistsAndShowConfirmationDialog),
        filter(({ dataStoreKey }) => dataStoreKey === ACCESS_RIGHTS_STORE_KEY),
        tap(async ({ formStoreKey, dataStoreKey }) => {
          const rowData = await this.dataStore$
            .pipe(select(getTcData, { storeKey: dataStoreKey }), take(1))
            .toPromise();

          const formData = await this.formStore$
            .pipe(
              select(getTcSmartFormCurrentModel, { storeKey: formStoreKey }),
              take(1)
            )
            .toPromise();

          const existingItem = rowData.find(
            (d) => d.userLogin === formData.userLogin
          );

          if (!existingItem) {
            // A new item was created
            this.store$.dispatch(
              submitTcSmartFormCurrentModel({ storeKey: formStoreKey })
            );
            this.store$.dispatch(
              closeTcGridDetailsDialog({
                storeKey: formStoreKey,
              })
            );
            return;
          }

          if (existingItem._id === formData._id) {
            // Update existing item
            this.store$.dispatch(
              submitTcSmartFormCurrentModel({ storeKey: formStoreKey })
            );
            this.store$.dispatch(
              closeTcGridDetailsDialog({
                storeKey: formStoreKey,
              })
            );
          } else {
            // There's another confirguration for the same user. Ask for confirmation to update the existing entry instead of the current entry
            const isConfirmed = await this.dialog
              .open(TcPromptDialogComponent, {
                width: '33rem',
                data: {
                  title: 'globalLabels.warning',
                  text: 'access-rights.modify-existing-user.confirmation',
                },
              })
              .afterClosed()
              .pipe(first())
              .toPromise();

            if (isConfirmed) {
              this.store$.dispatch(
                showConfirmationDialogToOverrideExistingConfiguration({
                  storeKey: formStoreKey,
                  item: {
                    ...formData,
                    _id: existingItem._id,
                  },
                })
              );
            }
          }
        })
      );
    },
    { dispatch: false }
  );
}
