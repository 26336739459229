import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { TcButtonsModule } from '@tc/buttons';
import { TcCoreModule } from '@tc/core';
import { TcSmartFormModule } from '@tc/smart-form';
import { ImpersonatePopupComponent } from './components/smart/impersonate-popup/impersonate-popup.component';
import { ImpersonateEffects } from './store/impersonate.effects';

const dialogs = [ImpersonatePopupComponent];

@NgModule({
  declarations: [...dialogs],
  imports: [
    CommonModule,
    TcCoreModule,
    TcSmartFormModule,
    TcButtonsModule,
    EffectsModule.forFeature([ImpersonateEffects]),
  ],
  exports: [...dialogs],
})
export class AuthModule {}
