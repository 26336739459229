import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TcNavMenuNodes } from '@tc/core';
import { getAbilities } from '@tc/permissions';
import { take, tap } from 'rxjs/operators';
import {
  initMenu,
  setMenuItems,
} from '../../../../modules/menu/store/menu.actions';
import { CustomRoutes } from '../../../typings/custom-routes.enum';
import { PermissionAction } from '../../../typings/permission-action.enum';
import { PermissionSubject } from '../../../typings/permission-subject.enum';

@Injectable()
export class MenuEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>
  ) {}

  initMenu = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initMenu),
        tap(async () => {
          const items = await this.getMenuItems();
          this.store$.dispatch(setMenuItems({ items }));
        })
      ),
    { dispatch: false }
  );

  private async getMenuItems() {
    const abilities = await this.store$
      .select(getAbilities)
      .pipe(take(1))
      .toPromise();

    /**
     * NOTE: If this array gets changed probably the array form apps\frontend\src\app\custom\pages\home-page\home.component.ts:126:7
     *       needs to be updated
     */
    const items: TcNavMenuNodes[] = [
      {
        icon: 'forum',
        label: 'interactions-feed-page.title',
        class: 'menu-item-bold',
        route: CustomRoutes.InteractionsFeed,
        hidden: abilities.cannot(
          PermissionAction.List,
          PermissionSubject.Interactions
        ),
      },
      {
        icon: 'groups',
        label: 'organismes-page.title',
        class: 'menu-item-bold',
        route: CustomRoutes.Organismes,
        hidden: abilities.cannot(
          PermissionAction.List,
          PermissionSubject.Organismes
        ),
      },
      {
        icon: 'record_voice_over',
        label: 'interlocuteurs-page.title',
        route: CustomRoutes.Interlocuteurs,
        hidden: abilities.cannot(
          PermissionAction.List,
          PermissionSubject.Interlocuteurs
        ),
      },
      {
        icon: 'message',
        label: 'interactions-page.title',
        class: 'menu-item-bold',
        route: CustomRoutes.Interactions,
        hidden: abilities.cannot(
          PermissionAction.List,
          PermissionSubject.Interactions
        ),
      },
      {
        icon: 'dashboard',
        label: 'tableau-de-bord-page.title',
        class: 'menu-item-bold',
        route: CustomRoutes.TableauDeBord,
        hidden: abilities.cannot(
          PermissionAction.Dashboard,
          PermissionSubject.Interactions
        ),
      },
      {
        icon: 'verified_user',
        label: 'access-rights-page.title',
        class: 'menu-item-bold',
        route: CustomRoutes.AccessRights,
        hidden: abilities.cannot(
          PermissionAction.Create,
          PermissionSubject.AccessRights
        ),
      },
    ];

    return items;
  }
}
