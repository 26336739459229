import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { TcCoreModule, TcGridModule } from '@tc/core';
import { TcDialogModule } from '@tc/dialog';
import { TcSmartFormModule } from '@tc/smart-form';
import { AccessRightsDetailComponent } from './components/access-rights-detail/access-rights-detail.component';
import { AccessRightsGridComponent } from './components/access-rights-grid.component';
import { AccessRightsEffects } from './store/access-rights.effects';

const components = [AccessRightsGridComponent, AccessRightsDetailComponent];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcCoreModule,
    TcDialogModule,
    TcGridModule,
    TcSmartFormModule,
    EffectsModule.forFeature([AccessRightsEffects]),
  ],
  exports: [...components],
})
export class AccessRightsModule {}
