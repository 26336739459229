import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BaseTcStorePayload } from '@tc/abstract';
import {
  closeTcGridDetailsDialog,
  getNestedValue,
  openTcGridConfirmPopup,
  openTcGridDetailsPopup,
} from '@tc/core';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  deleteItem,
  getTcData,
  NgRxTcDataState,
  softDeleteItem,
} from '@tc/data-store';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  NgRxTcSmartFormState,
  setTcSmartFormModel,
  submitTcSmartFormCurrentModel,
} from '@tc/smart-form';
import { hasValue } from '@tc/utils';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import {
  addTcGridButtonClicked,
  closeAndOpenAnotherDialogButtonClicked,
  closeDialogAndNavigateButtonClicked,
  deactivateTcGridButtonClicked,
  deleteTcGridButtonClicked,
  editTcGridButtonClicked,
  navigate,
  openEmailApp,
  openFilteredDataDetail,
  openPhoneApp,
  submitAndCloseDialogButtonClicked,
} from '../actions';
import {
  AddTcGridButtonClickedPayload,
  CloaseAndNavigateButtonClickedPlayload,
  CloseAndOpenAnotherDetailButtonClickedPlayload,
  DeactivateTcGridButtonClickedPayload,
  DeleteTcGridButtonClickedPayload,
  EditTcGridButtonClickedPayload,
  NavigatePayload,
  OpenEmailAppPayload,
  OpenFilteredDataDetailPayload,
  OpenPhoneAppPayload,
} from '../payloads';

@Injectable()
export class TcSmartListDetailEffects {
  dataStore$: Observable<NgRxTcDataState>;
  formStore$: Observable<NgRxTcSmartFormState>;

  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions,
    private readonly router: Router
  ) {
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.formStore$ = this.store$.pipe(
      select(DEFAULT_TC_SMART_FORM_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  /**
   * Add tc grid button clicked effect
   */
  addTcGridButtonClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addTcGridButtonClicked),
        tap(async (payload: AddTcGridButtonClickedPayload) => {
          const {
            storeKey,
            detailsPopupComponent,
            defaultModel,
            width,
            height,
            supressCloseConfirmation,
          } = payload;
          // if a component was provided in grid config, a store action will be dispatched in order to display that component
          if (detailsPopupComponent) {
            this.store$.dispatch(
              openTcGridDetailsPopup({
                storeKey,
                detailsPopupComponent,
                data: { _id: null, ...defaultModel },
                width: width ?? '80%',
                height,
                supressCloseConfirmation,
              })
            );

            // Populate form with default values
            this.store$.dispatch(
              setTcSmartFormModel({
                storeKey,
                model: { _id: null, ...defaultModel },
              })
            );
          } else {
            // TODO: Maybe in the future
            // else a store action will be dispatched in order to insert an empty row in the grid
            // this.store$.dispatch(
            //   insertTcGridEmptyRow({
            //     storeKey,
            //   })
            // );
          }
        })
      ),
    { dispatch: false }
  );

  /**
   *  effect to update and refresh tc-data
   */
  editTcGridButtonClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editTcGridButtonClicked),
        tap(async (payload: EditTcGridButtonClickedPayload) => {
          const {
            storeKey,
            detailsPopupComponent,
            rowData,
            width,
            height,
            supressCloseConfirmation,
          } = payload;

          // Open popup
          this.store$.dispatch(
            openTcGridDetailsPopup({
              storeKey: storeKey,
              detailsPopupComponent: detailsPopupComponent,
              data: rowData,
              width: width ?? '80%',
              height,
              supressCloseConfirmation,
            })
          );
        })
      ),
    { dispatch: false }
  );

  /**
   *  effect to remove entity and refresh tc-data
   */
  deleteTcGridButtonClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteTcGridButtonClicked),
        tap(async (payload: DeleteTcGridButtonClickedPayload) => {
          const { storeKey, rowData, detailsPopupComponent, width, height } =
            payload;

          // Open popup
          this.store$.dispatch(
            openTcGridConfirmPopup({
              storeKey: storeKey,
              detailsPopupComponent: detailsPopupComponent,
              data: rowData,
              width: width,
              height,
              callbackAction: deleteItem,
              actionPayload: {
                storeKey: storeKey,
                item: rowData,
              },
            })
          );
        })
      ),
    { dispatch: false }
  );

  deactivateTcGridButtonClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deactivateTcGridButtonClicked),
        tap(async (payload: DeactivateTcGridButtonClickedPayload) => {
          const {
            storeKey,
            rowData,
            detailsPopupComponent,
            width,
            height,
            deactivationField,
            deactivationValue,
          } = payload;

          let deactivatedItem = { ...rowData };
          deactivatedItem[deactivationField] = deactivationValue;

          // Open popup
          this.store$.dispatch(
            openTcGridConfirmPopup({
              storeKey: storeKey,
              detailsPopupComponent: detailsPopupComponent,
              data: rowData,
              width: width,
              height,
              callbackAction: softDeleteItem,
              actionPayload: {
                storeKey: storeKey,
                item: deactivatedItem,
              },
            })
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * submit model and close details dialog
   * TODO: Maybe in the future
   *     - this automatically closes the form even if there was an error,
   *       a better user experience would be to keep the form open
   */
  submitAndCloseDialogButtonClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(submitAndCloseDialogButtonClicked),
        tap(async (payload: BaseTcStorePayload) => {
          const { storeKey } = payload;

          // Submit the current model
          this.store$.dispatch(
            submitTcSmartFormCurrentModel({
              storeKey,
            })
          );

          // Close the dialog
          this.store$.dispatch(
            closeTcGridDetailsDialog({
              storeKey,
            })
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * close details dialog and navigate to another route
   */
  closeDialogAndNavigateButtonClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeDialogAndNavigateButtonClicked),
        tap(async (payload: CloaseAndNavigateButtonClickedPlayload) => {
          const { storeKey, route, queryParams } = payload;

          // Close the dialog
          this.store$.dispatch(
            closeTcGridDetailsDialog({
              storeKey,
            })
          );

          // Navigate to another route
          this.router.navigate([route], { queryParams });
        })
      ),
    { dispatch: false }
  );

  /**
   * Close the dialog and open another after the closing is done
   */
  closeAndOpenAnotherDialogButtonClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeAndOpenAnotherDialogButtonClicked),
        tap(async (payload: CloseAndOpenAnotherDetailButtonClickedPlayload) => {
          const {
            parentGridStoreKey,
            detailsPopupComponent,
            rowData,
            width,
            height: heigth,
          } = payload;

          this.store$.dispatch(
            closeTcGridDetailsDialog({
              storeKey: parentGridStoreKey,
              callbackAction: openTcGridDetailsPopup,
              callbackActionPayload: {
                storeKey: parentGridStoreKey,
                detailsPopupComponent: detailsPopupComponent,
                data: rowData,
                width: width,
                height: heigth,
              },
            })
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Navigates to a route with query params
   * Can be chained with any other action as a callback
   */
  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigate),
        tap(async (payload: NavigatePayload) => {
          const {
            route,
            queryParams,
            refreshRoute,
            callbackAction,
            callbackActionPayload,
          } = payload;

          // Navigate to another route
          if (refreshRoute) {
            // Navigate to root and then immediately navigate to the wanted route so that
            // if we navigate to the same route the components will get reloaded.
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate([route], { queryParams }));
          } else {
            this.router.navigate([route], { queryParams });
          }

          // If we have a callback action dispatch it.
          if (callbackAction) {
            this.store$.dispatch(callbackAction(callbackActionPayload));
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Opens details pop-up of a filtered data.
   * Usually dispatched as a callbackAction by the navigate action
   */
  openFilteredDataDetail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openFilteredDataDetail),
        tap(async (payload: OpenFilteredDataDetailPayload) => {
          const {
            storeKey,
            filterProperty,
            filterValue,
            detailsPopupComponent,
          } = payload;

          // Wait for the data that interests us to load into the store/grid
          const rowData = await this.dataStore$
            .pipe(
              select(getTcData, { storeKey }),
              filter(
                (data) =>
                  data.length === 1 &&
                  getNestedValue(data[0], filterProperty) === filterValue
              ),
              map((data) => data[0]),
              take(1)
            )
            .toPromise();

          // Open the data's detail pop-up
          this.store$.dispatch(
            openTcGridDetailsPopup({
              storeKey,
              detailsPopupComponent: detailsPopupComponent,
              data: rowData,
            })
          );
        })
      ),
    { dispatch: false }
  );

  openEmailApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openEmailApp),
        tap(async (payload: OpenEmailAppPayload) => {
          const { rowData, emailField } = payload;

          let mailToText = `mailto:${rowData[emailField]}`;

          window.location.href = mailToText;
        })
      ),
    { dispatch: false }
  );

  openPhoneApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openPhoneApp),
        tap(async (payload: OpenPhoneAppPayload) => {
          const { rowData, phoneField } = payload;

          let telText = `tel:${rowData[phoneField]}`;

          window.location.href = telText;
        })
      ),
    { dispatch: false }
  );
}
