import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MaterialColor, TcConfigTypes } from '@tc/abstract';
import { TcSmartDetailPopupComponent } from '@tc/advanced-components';
import {
  closeTcGridDetailsDialog,
  formlyColumn,
  formlyControl,
  TcComponentLookup,
  TcFormlyComponent,
} from '@tc/core';
import { checkIfConfigurationExistsAndShowConfirmationDialog } from '../../store/access-rights.actions';
import { ACCESS_RIGHTS_STORE_KEY } from '../../store/constants';

@TcComponentLookup('AccessRightsDetailComponent')
@Component({
  selector: 'access-rights-detail',
  templateUrl: './access-rights-detail.component.html',
  styleUrls: ['./access-rights-detail.component.scss'],
})
export class AccessRightsDetailComponent extends TcSmartDetailPopupComponent {
  readonly formStoreKey = ACCESS_RIGHTS_STORE_KEY;
  private _title = 'access-rights.detail.edit.title';

  constructor(store: Store, @Inject(MAT_DIALOG_DATA) data) {
    super(store, data);
    if (!data.entityData['_id']) {
      this._title = 'access-rights.detail.add.title';
    }
  }

  async setFormConfig() {
    this.dialogConfig.dialogStoreKey = this.formStoreKey;
    this.dialogConfig.hasfooter = false;

    this.formConfig = {
      configType: TcConfigTypes.TcForm,
      storeKey: this.formStoreKey,
      autoSubmit: false,
      fieldConfigs: [],
      titleConfig: {
        storeKey: this.formStoreKey,
        configType: TcConfigTypes.TcDetailTitle,
        titlePrefix: this._title,
        buttonsList: [
          {
            label: 'annuler',
            color: MaterialColor.Warn,
            matIcon: 'cancel',
            action: closeTcGridDetailsDialog,
            actionPayload: {
              storeKey: this.formStoreKey,
            },
          },
          {
            label: 'enregister',
            color: MaterialColor.Primary,
            matIcon: 'save',
            action: checkIfConfigurationExistsAndShowConfirmationDialog,
            actionPayload: {
              dataStoreKey: this.formStoreKey,
              formStoreKey: this.formStoreKey,
            },
          },
        ],
      },
    };

    await this._initFields();
  }

  private _initFields() {
    this.formConfig.fieldConfigs = [
      formlyColumn({
        fields: [
          formlyControl({
            key: 'userLogin',
            type: TcFormlyComponent.FormlyInput,
            colSpan: 12,
            expressionProperties: {
              'model.userLogin': 'model?.userLogin?.toLowerCase()',
            },
            templateOptions: {
              required: true,
              type: 'text',
            },
          }),
          formlyControl({
            key: 'role',
            type: TcFormlyComponent.FormlySelect,
            defaultValue: 'ADM',
            colSpan: 12,
            templateOptions: {
              required: true,
              options: [
                {
                  value: 'ADM',
                  label: 'ADM',
                },
                {
                  value: 'MC',
                  label: 'MC',
                },
                {
                  value: 'M',
                  label: 'M',
                },
                {
                  value: 'MS',
                  label: 'MS',
                },
                {
                  value: 'CS',
                  label: 'CS',
                },
                {
                  value: 'D',
                  label: 'D',
                },
                {
                  value: 'DS',
                  label: 'DS',
                },
                {
                  value: 'ASO',
                  label: 'ASO',
                },
              ],
            },
          }),
        ],
      }),
    ];
  }
}
