import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcPage } from '@tc/core';

@Component({
  selector: 'app-access-rights-page',
  templateUrl: './access-rights-page.component.html',
  styleUrls: ['./access-rights-page.component.scss'],
})
export class AccessRightsPageComponent extends TcPage {
  constructor(store: Store<any>) {
    super(store);
  }
}
